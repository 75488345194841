// import {} from "../assets";

export const navLinks = [
  // {
  //   id: "group-addresses",
  //   href: "",
  //   title: "Адреса групп",
  // },
  {
    id: "questions",
    href: "",
    title: "Вопросы новичка",
  },
  {
    id: "regional-site",
    href: "https://aaspb.ru",
    title: "Городской сайт",
  },
  {
    id: "about",
    href: "",
    title: "О нас",
  },
  {
    id: "schedule",
    href: "",
    title: "Расписание",
  },
];

export const about = [
  {
    id: "about",
    content: "Анонимные Алкоголики – это содружество, объединяющее мужчин и женщин, которые делятся друг с другом своим опытом, силами и надеждами с целью помочь себе и другим избавиться от алкоголизма.\n\n Единственным условием для членства является желание бросить пить.\n\n Нет ни вступительных, ни членских взносов. Мы сами содержим себя на наши добровольные пожертвования.\n\n Содружество не связано ни с какой сектой, вероисповеданием, политическим направлением, организацией или учреждением; стремится не вступать в полемику по каким бы то ни было вопросам, не поддерживает и не выступает против чьих бы то ни было интересов.\n\n Наша главная цель – оставаться трезвыми и помочь другим алкоголикам обрести трезвость.",
  }
]

export const groups = [
  {
    name: "Купчинская",
    metro: "Купчино",
    adres: "ул. Олеко Дундича, д. 35, к. 1, вход со двора.",
    location: [59.828916, 30.399762],
    tel: "+79818551897",
    contactFace: "",
    descr: "",
    warning: "",
    schedule: [
      {
        mon: "15:30\n21:15",
        tue: "21:15\n",
        wed: "21:15\n",
        thu: "21:15\n",
        fri: "21:15\n",
        sat: "14:15 (о)\n",
        sun: "14:15 (о)\n",
      },
    ],
  },
  {
    name: "Новый Взгляд",
    metro: "Проспект Славы",
    adres: "Пр.Славы, 4 (2 этаж).",
    location: [59.855308, 30.370558],
    tel: "+79531424314",
    contactFace: "",
    descr: "",
    warning: "В первый вторник месяца собрания нет!",
    schedule: [
      {
        mon: "19:00\n",
        tue: "19:00\n",
        wed: "\n",
        thu: "19:00\n",
        fri: "\n",
        sat: "19:00 (о)\n",
        sun: "\n"
      },
    ],
  },
  {
    name: "Правило 62",
    metro: "Обводный канал",
    adres: "Казанская ул., д. 37",
    location: [59.929520, 30.310272],
    tel: "+79119901244",
    contactFace: "",
    descr: "",
    warning: "",
    schedule: [
      {
        mon: "20:00 (о)\n",
        tue: "20:00 (о)\n",
        wed: "20:00 (о)\n",
        thu: "20:00 (о)\n",
        fri: "20:00 (о)\n",
        sat: "20:00 (о)\n",
        sun: "20:00 (о)\n"
      },
    ],
  },
  {
    name: "Синопская",
    metro: "Обводный канал",
    adres: "ул. Боровая, 112 б.",
    location: [59.908173, 30.341093],
    tel: "+79522604799",
    contactFace: "Алексей",
    descr: "4-я суббота месяца – спикерское.",
    warning: "",
    schedule: [
      {
        mon: "20:00 (о)\n",
        tue: "\n",
        wed: "20:00 (о)\n",
        thu: "\n",
        fri: "\n",
        sat: "19:00 (о)\n",
        sun: "\n"
      },
    ],
  },
];

export const citySite = [
  {
    id: "sitySite-1",
    name: "Анонимные Алкоголики Северо-Запада",
    url: "https://aaspb.ru",
    scheduleUrl: "https://aaspb.ru/raspisanie-new",
  }
]

export const questionsAA = [
  {
    id: "question-1",
    question: "Бывало ли так, что вы решали не пить неделю или более, но вас хватало только на пару дней?",
    ansver: 'Большинство из нас, членов АА, давали самые разные обещания самим себе и своим семьям. Но мы не могли их сдержать. Затем мы пришли в АА. Там нам сказали: "Попытайтесь не пить только сегодня." (Если вы сегодня не выпьете, то сегодня и не напьетесь.)',
  },
  {
    id: "question-2",
    question: "Хотелось ли вам, чтобы окружающие перестали говорить вам о вашем пьянстве и о том, что вам следует делать?",
    ansver: "В АА мы никому не указываем, что им следует делать. Мы просто рассказываем, как мы пили сами, к каким это привело неприятностям и как нам удалось это прекратить. Мы будем рады вам помочь, если этого захотите вы.",
  },
  {
    id: "question-3",
    question: "Пытались ли вы переключаться с одного вида выпивки на другой в надежде, что это поможет вам не напиться?",
    ansver: "Мы перепробовали все. Мы разбавляли выпивку, пили только пиво, старались не смешивать, пытались пить только в выходные дни. Что бы вы ни сказали - мы это тоже пробовали. И когда мы выпивали что бы то ни было, содержащее алкоголь, - мы, как правило, напивались.",
  },
  {
    id: "question-4",
    question: "Приходилось ли вам в течение последнего года выпивать утром, чтобы обрести способность начать новый день?",
    ansver: 'Вам нужно опохмелиться, чтобы двигаться дальше, или чтобы прекратило трясти? Это один из верных признаков того, что вы пьете не "как все."',
  },
  {
    id: "question-5",
    question: "Случалось ли вам завидовать людям, которые могут пить без неприятных последствий?",
    ansver: "Время от времени многие из нас задумывались, почему мы отличаемся от большинства других людей, которые могут контролировать свою выпивку.",
  },
  {
    id: "question-6",
    question: "Случались ли у вас в течение последнего года проблемы из-за выпивки?",
    ansver: "Будем честными! Врачи утверждают, что если возникает проблема, связанная с алкоголем, и вы продолжаете пить, то эта проблема может только ухудшаться — она никогда не облегчается. В конце концов, вы можете либо погибнуть, либо до конца дней оказаться в закрытой больнице. Единственная надежда - прекратить пить.",
  },
  {
    id: "question-7",
    question: "Возникали ли у вас из-за выпивки неприятности в семье?",
    ansver: "До тех пор, пока мы не пришли в АА, многие из нас говорили, что к выпивке нас подталкивают домашние проблемы или поведение окружающих. Мы отказывались видеть, что именно наше пьянство усугубляло все неприятности. Оно никогда не решало никаких проблем.",
  },
  {
    id: "question-8",
    question: 'Случалось ли так, что, выпивая в компании, вы старались перехватить "дополнительный" стаканчик, потому что вам не хватило?',
    ansver: 'Большинство из нас предварительно "чуть-чуть принимали", если ожидалось, что это будет "не та" компания. И если выпивки, по нашему мнению, оказывалось недостаточно, мы шли куда-нибудь "добавить".',
  },
  {
    id: "question-9",
    question: "Утверждаете ли вы, что можете перестать пить в любой момент, как только захотите, хотя часто напиваетесь даже тогда, когда совсем не собираетесь этого делать?",
    ansver: "Многие из нас обманывали себя, считая будто мы пьем по желанию. Придя в АА, мы поняли, что когда мы начинали пить, мы уже не могли остановиться.",
  },
  {
    id: "question-10",
    question: "Приходилось ли вам прогуливать работу или занятия в связи с выпивками?",
    ansver: 'Многие из нас теперь признаются, что частенько сказывались по телефону "больными", чтобы не выходить на работу, хотя на самом деле были пьяны или в тяжелом похмелье.',
  },
  {
    id: "question-11",
    question: "Случались ли у вас провалы в памяти?",
    ansver: "Имеются в виду случаи, когда мы пили в течение многих часов или нескольких дней, а потом ничего не могли вспомнить. Придя в АА, мы убедились, что это один из явных признаков алкоголизма.",
  },
  {
    id: "question-12",
    question: "Появлялось ли у вас когда-либо ощущение, что если бы вы не пили, то ваша жизнь была бы лучше?",
    ansver: "Многие из нас начинали пить потому, что после выпивки жизнь казалась лучше, по крайней мере, на некоторое время. К моменту прихода в АА мы почувствовали, что попали в ловушку. Мы пили чтобы жить, а жили - чтобы пить. Мы до смерти устали быть все время больными и усталыми.",
  },
]

export const questionsAABottom = [
  {
    id: "questionsAABottom-1",
    title: "Итак, что у Вас получилось? Вы ответили «да» на четыре вопроса или более?",
    text: "Если так, то Вы, вероятно, попали в беду. Почему мы так считаем? Потому что об этом говорили тысячи людей в АА в течение многих лет. Они узнали правду о себе на своем собственном горьком опыте. И все же только Вы можете решить, нужно ли вам АА. Попытайтесь подойти к этому непредвзято. Если скажете ДА, мы будем рады показать Вам, как мы сами бросили пить. Просто приходите. АА не обещает решить Ваши жизненные проблемы. Но мы можем показать Вам, как мы научились жить без выпивки “по одному дню”. Мы воздерживаемся только от этой первой рюмки. Если не будет первой, то не будет и десятой. И когда мы избавились от алкоголя, мы увидели, что наша жизнь стала гораздо более интересной.",
  }
]
